<template>
  <Breadcrumb menu="料金案内" />
  <div class="page-header">料金案内</div>
  <div class="content">
    <plan/>
    <div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../components/atoms/Breadcrumb.vue'
import Plan from '@/components/molecules/Plan.vue'

export default {
  name: 'News',
  components: {
    Breadcrumb, Plan,
  },
  data() {
  },
}
</script>

<style lang="scss">
.page-header {
  background: #ecf4f7;
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-top: #cee7f0 1px solid;
  color: #5c63ae;
}

.content {
  margin: auto;
  padding-top: 30px;
  min-height: 50vh;
  max-width: 800px;
  h1 {
    text-align: center;
  }
}

@media (min-width: 751px) {
  .content {
    width: 80%;
  }
}

@media screen and (max-width: 750px) {
  .content {
    width: 100%;
  }
}
</style>